import { useState, useContext, useEffect } from 'react';
import { Layout, ConfigProvider, theme } from 'antd';
import Sidebar from './components/layout/Sidebar';
import MainLayout from './components/layout/MainLayout';
import { ThemeProvider, ThemeContext } from './contexts/ThemeContext';
import { FontProvider } from './contexts/FontContext';
import { ChatProvider } from './contexts/ChatContext';
import { initializeModelAliases } from './utils/modelConfig';
import { DesktopOutlined } from '@ant-design/icons';
import './styles/layout.css';

const ScreenSizeAlert = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasShown, setHasShown] = useState(false);

  useEffect(() => {
    const hasShownAlert = localStorage.getItem('hasShownScreenSizeAlert');
    if (!hasShownAlert && window.innerWidth <= 768) {
      setIsVisible(true);
      setHasShown(true);
      localStorage.setItem('hasShownScreenSizeAlert', 'true');
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={`screen-size-alert ${isVisible ? 'visible' : ''}`}>
      <div className="screen-size-alert-content">
        <DesktopOutlined className="screen-size-alert-icon" />
        <span>EveryGPT works best on larger screens for optimal experience</span>
        <button className="screen-size-alert-close" onClick={handleClose}>×</button>
      </div>
    </div>
  );
};

const AppContent = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    // Initialize model aliases
    initializeModelAliases();
  }, []);

  // Apply theme to document
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: '#1677ff',
          borderRadius: 6,
        },
      }}
    >
      <Layout className="app-container">
        <ScreenSizeAlert />
        <Sidebar collapsed={collapsed} onCollapse={setCollapsed} />
        <MainLayout collapsed={collapsed} />
      </Layout>
    </ConfigProvider>
  );
};

const App = () => {
  return (
    <ThemeProvider>
      <FontProvider>
        <ChatProvider>
          <AppContent />
        </ChatProvider>
      </FontProvider>
    </ThemeProvider>
  );
};

export default App;
